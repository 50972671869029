import {Controller} from "stimulus"

export default class extends Controller {
    static targets = [
        'secondaryField', 'secondSelect',
        'heterologous',
        'doseNumber',
        'firstDoseVaccineId', 'secondDoseVaccineId', 'boosterDoseVaccineId', "thirdDoseVaccineId",
        'firstDoseMandatoryLabel', 'secondDoseMandatoryLabel', 'boosterDoseMandatoryLabel', "thirdDoseMandatoryLabel",
        'fluVaccineMandatoryLabel'];
    SecondaryFieldHTMLValues = {};

    connect() {
        document.querySelectorAll('select[data-action*="change->form#filterValuesOnSecondaryFields"]').forEach(
            primaryField => {
                this._filterValuesOnSecondaryFields(primaryField)
            }
        );
        this.showEnabledAttributes();
    }

    showSendingInProgressMessage(event) {
        event.preventDefault();
        setTimeout(() => $('#sendingInProgress').modal('show'), 1000);
        event.target.submit();
    }

    toggleDisabledAttributeOnSecondaryFields(event) {
        let primaryField = event.target;
        this.secondaryFieldTargets.forEach(
            secondaryField => {
                if (primaryField.getAttribute('name') == secondaryField.dataset.formPrimaryFieldName) {
                    secondaryField.querySelectorAll('select, input, textarea, label').forEach(
                        secondaryFieldElement => {
                            if ((new RegExp('^' + secondaryField.dataset.formPrimaryFieldValueTrigger + '$')).test(primaryField.value)) {
                                secondaryFieldElement.removeAttribute('disabled')
                                secondaryFieldElement.classList.remove('disabled');
                            } else {
                                secondaryFieldElement.setAttribute('disabled', true)
                                secondaryFieldElement.classList.add('disabled');
                            }
                        }
                    )
                }
            }
        );
    }

    filterValuesOnSecondaryFields(event) {
        let primaryField = event.target;
        this._filterValuesOnSecondaryFields(primaryField);
    }

    _filterValuesOnSecondaryFields(primaryField) {
        this.secondaryFieldTargets.forEach(
            secondaryField => {
                if (primaryField.getAttribute('name') == secondaryField.dataset.formPrimaryFieldName) {

                    var secondaryFieldSelect = secondaryField.getElementsByTagName('select')[0];

                    if (!(secondaryFieldSelect.id in this.SecondaryFieldHTMLValues)) {
                        this.SecondaryFieldHTMLValues[secondaryFieldSelect.id] = secondaryFieldSelect.innerHTML;
                    } else {
                        var currentValue = secondaryFieldSelect.value;
                        secondaryFieldSelect.innerHTML = this.SecondaryFieldHTMLValues[secondaryFieldSelect.id];
                        secondaryFieldSelect.value = currentValue;
                    }

                    secondaryField.querySelectorAll('option').forEach(
                        optionElement => {
                            if ((primaryField.value != '') &&
                                (!(optionElement.dataset.formPrimaryFieldValueTrigger == primaryField.value))) {
                                optionElement.remove();
                            }
                        }
                    );
                }
            }
        );
    }

    showEnabledAttributes() {
        this.doseNumberTargets.forEach(
            target => {
                if (target.checked){
                    document.querySelectorAll('[data-is-heterologous]').forEach(
                        isHeterologous => {
                            isHeterologous.addEventListener('click', () => {
                                this.switchConfiguration(target.value, isHeterologous.value)

                            })

                        })
                }
            })
        document.querySelectorAll('[data-dosenumber]').forEach(
            dose => {
                dose.addEventListener('click', () => {
                    this.isHeterologous(dose.value)
                })
            }
        )
    }

    isHeterologous(doseValue) {
        if (doseValue == 1) {
            this.hideAllAttributes();
        } else if (doseValue >= 2) {
            this.showIsHeterologousAttributes();
            this.switchConfiguration(doseValue, this.getSelectedValue(this.heterologousTargets))
            document.querySelectorAll('[data-is-heterologous]').forEach(
                isHeterologous => {
                    isHeterologous.addEventListener('click', () => {
                        this.switchConfiguration(doseValue, isHeterologous.value)

                    })

                })
        }
    }

    switchConfiguration(doseValue, isHeterologous) {
        if (doseValue == 2) {
            this.hideSecondDoseVaccineId();
            this.hideBoosterDoseReason();
            this.hideThirdDoseVaccineId();
            if (isHeterologous == 'true') {
                this.showFirstDoseVaccineId();
            } else {
                this.hideFirstDoseVaccineId();
            }
        } else if (doseValue == 3) {
            this.showBoosterDoseVaccineId();
            this.hideThirdDoseVaccineId();
            if (isHeterologous == 'true') {
                this.showFirstDoseVaccineId();
                this.showSecondDoseVaccineId();
            } else {
                this.hideFirstDoseVaccineId();
                this.hideSecondDoseVaccineId();
            }
        } else if (doseValue == 4) {
            this.showBoosterDoseVaccineId();
            if (isHeterologous == 'true') {
                this.showFirstDoseVaccineId();
                this.showSecondDoseVaccineId();
                this.showThirdDoseVaccineId();
            } else {
                this.hideFirstDoseVaccineId();
                this.hideSecondDoseVaccineId();
                this.hideThirdDoseVaccineId();
            }
        }
    }

    getSelectedValue(targets) {
        const selectedValueIndex = targets.findIndex(target => {
            return target.parentElement.classList.contains('active')
        })
        if (selectedValueIndex > -1) {
            return targets[selectedValueIndex].value
        }

    }

    showIsHeterologousAttributes() {
        this.heterologousTargets.forEach(target => {
            target.removeAttribute('disabled');
            target.parentElement.classList.remove('disabled');
        });
    }

    showBoosterDoseVaccineId() {
        this.showSelect(this.boosterDoseVaccineIdTarget);
        this.showMandatoryLabel(this.boosterDoseMandatoryLabelTarget)
    }

    showFirstDoseVaccineId() {
        this.showSelect(this.firstDoseVaccineIdTarget);
        this.showMandatoryLabel(this.firstDoseMandatoryLabelTarget)
    }

    showSecondDoseVaccineId() {
        this.showSelect(this.secondDoseVaccineIdTarget)
        this.showMandatoryLabel(this.secondDoseMandatoryLabelTarget)
    }

    showThirdDoseVaccineId() {
        this.showSelect(this.thirdDoseVaccineIdTarget)
        this.showMandatoryLabel(this.thirdDoseMandatoryLabelTarget)
    }

    showSelect(target) {
        target.removeAttribute('disabled');
        target.classList.remove('disabled');
    }

    showMandatoryLabel(target){
        target.classList.remove('d-none')
    }

    hideAllAttributes() {
        this.hideFirstDoseVaccineId();
        this.hideSecondDoseVaccineId();
        this.hideThirdDoseVaccineId();
        this.hideBoosterDoseReason();
        this.hideIsHeterologousAttributes();
    }

    hideIsHeterologousAttributes() {
        this.heterologousTargets.forEach(target => {
            target.setAttribute('disabled', true);
            target.parentElement.classList.add('disabled');
        });
    }


    hideFirstDoseVaccineId() {
        this.hideSelect(this.firstDoseVaccineIdTarget);
        this.hideMandatoryLabel(this.firstDoseMandatoryLabelTarget);
    }

    hideSecondDoseVaccineId() {
        this.hideSelect(this.secondDoseVaccineIdTarget);
        this.hideMandatoryLabel(this.secondDoseMandatoryLabelTarget);
    }

    hideThirdDoseVaccineId() {
        this.hideSelect(this.thirdDoseVaccineIdTarget);
        this.hideMandatoryLabel(this.thirdDoseMandatoryLabelTarget);
    }

    hideBoosterDoseReason() {
        this.hideSelect(this.boosterDoseVaccineIdTarget);
        this.hideMandatoryLabel(this.boosterDoseMandatoryLabelTarget);
    }

    hideSelect(target) {
        target.setAttribute('disabled', true);
        target.classList.add('disabled');
    }

    hideMandatoryLabel(target){
        target.classList.add('d-none')
    }

    hideMandatoryFluDateLabel(){
        this.fluVaccineMandatoryLabelTarget.classList.add('d-none')
    }

    showMandatoryFluDateLabel(){
        this.fluVaccineMandatoryLabelTarget.classList.remove('d-none')
    }

}