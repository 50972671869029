import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ 'fileField', 'fileFieldLabel', 'fileDetailsList', 'fileDetailsListTrigger']
    static values = { selectedMsg: String, unselectedMsg: String }

    updateUI() {
        const files = this.fileFieldTarget.files;

        let file_sizes = '';
        let file_details = '';

        for (var i = 0; i < files.length; i++) {
            file_sizes += this.bytesToSize(files[i].size);
            file_details += `<li>${files[i].name}  (${this.bytesToSize(files[i].size)})</li>`;

            if (i != files.length-1) {
                file_sizes += ', ';
            }
        }

        this.fileFieldLabelTarget.innerHTML = `${this.selectedMsgValue}: ${files.length} (${file_sizes})`;
        this.fileDetailsListTarget.innerHTML = file_details;
        this.fileDetailsListTriggerTarget.classList.remove('d-none');
    }

    bytesToSize(bytes) {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
}