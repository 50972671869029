import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["installBtn"];

    connect() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            this.installBtnTarget.classList.remove("d-none");
        });
    }

    install() {
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                this.installBtnTarget.classList.add("d-none");
                this.deferredPrompt = null;
            }
        });
    }
}