import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "date",
                       "text", "textChoicesModal" ];

    fillWithNow(event) {
        this._fillWithDate(this._getPastDate(0), true);
        event.preventDefault();
    }

    fillWithToday(event) {
        this._fillWithDate(this._getPastDate(0));
        event.preventDefault();
    }

    fillWithYesterday(event) {
        this._fillWithDate(this._getPastDate(1));
        event.preventDefault();
    }

    fillWithAWeekAgo(event) {
        this._fillWithDate(this._getPastDate(7));
        event.preventDefault();
    }

    fillWithAMonthAgo(event) {
        this._fillWithDate(this._getPastDate(30));
        event.preventDefault();
    }

    fillWithSelectedText(event) {
        if (this.element.dataset.fastFillMode == 'replace') {
            this.textTarget.value = event.target.innerText;
        } else if (this.element.dataset.fastFillMode == 'append') {
            this.textTarget.value = this.textTarget.value + ' ' + event.target.innerText;
        }

        this.textChoicesModalTargets.forEach(textChoicesModalTarget => $(textChoicesModalTarget).modal('hide'));

        this.textTarget.dispatchEvent(new Event('input', {bubbles: false, cancelable: true}));

        event.preventDefault;
    }



    _getPastDate(days_ago) {
        let past_date = new Date();
        past_date.setDate(past_date.getDate() - days_ago);
        return past_date;
    }

    _fillWithDate(selected_date, fillTime = false) {
        if (this.hasDateTarget && this.dateTarget.querySelectorAll('select[disabled]').length == 0) {
            this.dateTarget.querySelector('select[name*="(3i)"]').value = selected_date.getDate();
            this.dateTarget.querySelector('select[name*="(2i)"]').value = selected_date.getMonth()+1;
            this.dateTarget.querySelector('select[name*="(1i)"]').value = selected_date.getFullYear();

            let hourField = this.dateTarget.querySelector('select[name*="(4i)"]');
            if (hourField) { hourField.value = fillTime ? String(selected_date.getHours()).padStart(2, '0') : '00'}

            let minutesField = this.dateTarget.querySelector('select[name*="(5i)"]');
            if (minutesField) { minutesField.value = fillTime ? String(selected_date.getMinutes()).padStart(2, '0') : '00'}

        }
    }




}