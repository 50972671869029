import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['switch', 'memoryfulField'];
    storageKeyName = null;

    connect() {
        this.storageKeyName = `memoryfulFieldset[${this.element.name}]`;
        let storageKeyValue = JSON.parse(localStorage.getItem(this.storageKeyName));

        this.enabledStorage = this.switchTarget.checked = (storageKeyValue !== null);

        if (this.enabledStorage) {
            for (var fieldName in storageKeyValue) {
                this.loadFieldValue(fieldName, storageKeyValue[fieldName]);
            }
            window.scrollTo(0,0);
        }
    }

    toggleStorage() {
        if (this.enabledStorage = this.switchTarget.checked) {
            localStorage.setItem(this.storageKeyName, JSON.stringify({}));
            this.element.querySelectorAll('[data-action$="memoryful-fieldset#saveFieldValueOnEdit"]').forEach(
                field => this.saveFieldValue(field)
            );
        } else {
            localStorage.removeItem(this.storageKeyName);
        };
    }

    saveFieldValueOnEdit(event) {
        this.saveFieldValue(event.target);
    }

    saveFieldValue(field) {
        if (this.enabledStorage && (field.type != 'radio' || field.checked)) {
            var storageKeyValue = JSON.parse(localStorage.getItem(this.storageKeyName));
            storageKeyValue[field.name] = field.value;
            localStorage.setItem(this.storageKeyName, JSON.stringify(storageKeyValue));
        }
    }

    loadFieldValue(fieldName, fieldValue) {
        let field = this.element.querySelector(`[name="${fieldName}"]`);

        if (field.type == 'radio') {
            this.element.querySelector(`[name="${fieldName}"][value="${fieldValue}"]`).click();
        } else {
            field.value = fieldValue;

            field.dispatchEvent(new Event(field.tagName == 'SELECT' ? 'change' : 'input'));
            
        }
    }

}
