// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


// Stimulus setup .............................................................
import "stimulus"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
// ............................................................................


// Boostrap setup .............................................................
import "bootstrap"
import "../stylesheets/application"

document.addEventListener("turbolinks:load", ()=> {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
})
// ............................................................................


// Font Awesome setup .............................................................
import "@fortawesome/fontawesome-free/css/all";
// ............................................................................


// PWA setup ..................................................................
if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/service-worker.js')
        .then(() => { console.log('Service Worker Registered'); });
}
// ............................................................................




Rails.start()
Turbolinks.start()
ActiveStorage.start()
